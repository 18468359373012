<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined color="#f1f1f1">
        <v-card-title>Add YN Member</v-card-title>
        <v-card-text>
          <!-- Start Upload proposal section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :loading="loadingYnTeamItems"
                    v-model="member"
                    :items="ynTeamItems"
                    item-text="fullName"
                    item-value="id"
                    label="YN Member"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <div class="mt-2">
                <v-btn class="orange-bg white--text" @click="addUser">
                  Add YN Member
                </v-btn>
              </div>
            </v-container>
          </v-form>
          <!-- End Upload proposal section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  API_EMPLOYEES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    ynTeamItems: [],
    member: null,
    loadingYnTeamItems: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    // return true;
    return {
      member: {
        required
      }
    };
  },
  watch: {},
  async mounted() {
    this.getYnMembers();
  },
  async created() {
    let self = this;
    self.loading = true;
    Promise.all([
      await this.getComunityInfo()
      // await this.getRecipients()
    ]).then(function() {
      self.loading = false;
    });
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);
            self.createBreadcrumb();
            self.setInternalBreadcrumbs();
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Community details. Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
        });
    },
    async getYnMembers() {
      this.loadingYnTeamItems = true;
      // let comunityItems = dataModels.communityDetails;
      let self = this;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_EMPLOYEES
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.ynTeamItems = response.data.filter(el => el.id !== null);
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive list of YN Members. Something went wrong!",
              color: "red"
            });
          }

          this.loadingYnTeamItems = false;
        });
    },
    addUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_CUSTOMERS}/${self.customerId}/user/${this.member}`
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            if (response.data.error) {
              if (
                response.data.error ===
                "Cannot create user, email already in use."
              )
                self.snackbarContent =
                  "Cannot create user, email already in use.";
              else
                self.snackbarContent =
                  "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
              self.snackbarColor = "red";
            } else {
              self.snackbarContent = "User has been added";

              self.$router.push({
                name: "ManagePortalAccess",
                params: { customerId: this.customerId }
              });
            }
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        })
        .catch(res => {
          let error =
            "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
          if (res)
            error =
              "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";

          self.$snackbar.showMessage({
            content: error,
            color: "red"
          });
        });

      // this.saveCustomer();
    },
    setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Manage Portal Access",
          route: "/customers/" + this.customerId + "/manage-portal-access"
        },
        { title: "Add YN Member" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Manage Portal Access",
          route: "/customers/" + this.customerId + "/manager-portal-access"
        },
        { title: "Add YN Member" }
      ]);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      //return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    memberErrors() {
      const errors = [];
      if (!this.$v.newUser.member.$dirty) return errors;
      !this.$v.newUser.member.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
